$(function() {
  //caches a jQuery object containing the header element
  var header = $(".navbar");
  var width = window.screen.width;

  if(width > 767){
      $(window).scroll(function() {
        var scroll = $(window).scrollTop();
        if (scroll >= 200) {
            header.fadeOut()
        } else {
            header.fadeIn()
        }
      });
    }
});